<template>
  <div class="salarieBRIComponent">
    <div class="row mt-1 text-center">
      <div class="col-12 align-self-center" style="font-size: 1.3em;" v-if="bri.file_name">
        <a href="#" @click="getProtectedBRI(bri.id, bri.file_name)">Télécharger votre bilan retraite individuel </a> <br>
        <em><small> ( {{ sizeFormatter(bri.file_size) }} )</small> </em>
      </div>
      <div class="col-12 align-self-center" v-else>
        <em style="font-size: 1.3em;">Aucun bri encore chargé </em>
      </div>
    </div>
  </div>
</template>

<script>
import { APISalarieConnected } from '@/api/APISalarieConnected'
import { fileOperations } from '@/mixins/utilMixins'

const apiSalarieConnectedService = new APISalarieConnected()

export default {
  name: 'salarieBRIComponent',
  mixins: [
    fileOperations
  ],
  data : function () {
    return {
      // ---------- BRI --------------
      bri: '',
      briLoading: false
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
  },
  created: function() {
    this.getBRI()
  },
  methods: {
    // ------------------------ BRI ------------------------
    getBRI () {
      this.briLoading = true
      apiSalarieConnectedService.getBRI(this.token)
        .then((result) => {
          this.bri = result.data
        })
        .catch(() => {
          this.$refs.globalAPILoadingErrorModal.show()
        })
        .finally(() => {
          this.briLoading = false
        })
    },


    getProtectedBRI (bri_id, bri_name) {
      apiSalarieConnectedService.getOwnProtectedBRI(this.token, bri_id)
      .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', bri_name); //or any other extension
          document.body.appendChild(link);
          link.click();
        }, () => {
          this.$refs.globalAPILoadingErrorModal.show()
        })
    },

  }

}
</script>
