<template>
  <div class="salarieDashboard" >
    <formTitleComponent />
      <div class="container">
        <h1 class="form_div_title text-center">Mon tableau de bord</h1>
        <div class="form_div">
          <div class="form_content">

            <!-- Déjà connecté -->

            <div v-if="!isAuthenticated">
              <h2 class="text-center">
                Vous n'êtes pas connecté
              </h2>
              <p>
                Vous n'êtes pas connecté à votre compte
              </p>
              <b-button block variant="outline-primary" class="mt-2 btn-lg" @click="goToLogin">
                Se connecter
              </b-button>
            </div>

            <div v-else>
              <!-- ERROR MODAL -->
              <b-modal ref="globalAPILoadingErrorModal" hide-footer title="Erreur lors du chargement de votre profil">
                <div class="text-center">
                  <p>Une erreur s'est produite lors du chargement des informations de votre profil,
                  veuillez vérifier les données renseignées ou nous contacter
                  via notre <router-link to="/contact"> formulaire de contact</router-link>
                  si cette erreur persiste </p>
                  <b-button class="mt-3" variant="outline-success" @click="hideGlobalAPILoadingErrorModal">Fermer</b-button>
                </div>
              </b-modal>

              <!-- Update Etat civil -->
              <b-modal ref="etatCivilUpdatedModal" hide-footer title="Votre dossier a bien été mis à jour">
                <div class="text-center">
                  <p><strong>Votre dossier a bien été mis à jour</strong>, chargez maintenant vos documents pour
                    demander la validation de votre dossier par un conseiller Audiens </p>
                  <b-button class="mt-3" variant="outline-success" @click="hideEtatCivilUpdatedModalModal">Fermer</b-button>
                </div>
              </b-modal>

              <!-- Ask validation Salarie -->
              <b-modal ref="askSalarieValidationdModal" hide-footer title="Votre demande de validation a bien été prise en compte">
                <div class="text-center">
                  <p><strong>Votre demande de validation de votre dossier a bien été prise en compte</strong>.
                    Un conseiller Audiens validera vos informations au plus vite </p>
                  <b-button class="mt-3" variant="outline-success" @click="hideAskSalarieValidationdModal">Fermer</b-button>
                </div>
              </b-modal>

              <!-- Document Uploaded -->
              <b-modal ref="documentUploadedModal" hide-footer title="Votre document a bien été envoyé et enregistré">
                <div class="text-center">
                  <p><strong>Votre document a bien été envoyé.</strong> <br>
                    Une fois tous vos documents chargés, vous pourrez demander la validation final de votre dossier
                    auprès d'un conseiller Audiens
                  </p>
                  <b-button class="mt-3" variant="outline-success" @click="hideDocumentUploadedModal">Fermer</b-button>
                </div>
              </b-modal>


              <!-- Modify Document -->
              <b-modal ref="uploadDocumentModal" hide-footer>
                <template v-slot:modal-title>
                  <strong> Charger votre document </strong>
                </template>
                <div>
                  <b-form>
                    <b-form-group>
                        <b-form-file
                          required
                          class="text-truncate"
                          browse-text='Parcourir'
                          v-model="documentFile"
                          id="document-file-input"
                          placeholder="Choisissez un fichier"
                          drop-placeholder="Déposez un fichier ici"
                          @input="$v.documentFile.$touch()"
                          :state="$v.documentFile.$dirty ? !$v.documentFile.$error : null"
                        ></b-form-file>
                        <div class="form-error" v-if="!$v.documentFile.file_size_validation">Attention, la taille de votre document ne doit pas dépasser 5 MB.</div>
                        <div class="form-error" v-if="!$v.documentFile.file_extension_validation">Attention, votre fichier doit être au format pdf, jpg, jpeg ou png</div>
                        <div class="form-error" v-if="!$v.documentFile.name.maxLength">Assurez-vous que le nom de votre fichier comporte au plus 100 caractères</div>
                        <div class="form-error" v-if="!$v.documentFile.name.minLength">Assurez-vous que le nom de votre fichier comporte au moins 2 caractères</div>
                    </b-form-group>

                    <b-button
                      block :variant="uploadDocumentButtonStyle" class="mt-2"
                      @click='uploadDocument'
                      :disabled="uploadDocumentInProcess || $v.documentFile.$invalid  ">
                        <b-spinner small label="Upload document Spinner" v-if="uploadDocumentInProcess"></b-spinner> {{ uploadDocumentButtonText }}
                    </b-button>
                  </b-form>
                </div>
              </b-modal>

              <!-- Ask appointment Salarie -->
              <b-modal ref="askedSalarieAppointmentModal" hide-footer title="Votre demande de rendez-vous a bien été prise en compte">
                <div class="text-center">
                  <p><strong>Votre demande d'entretien téléphonique a bien été prise en compte</strong>.
                    Un conseiller Audiens vous contactera au plus vite pour convenir d'une date pour votre entretien téléphonique </p>
                  <b-button class="mt-3" variant="outline-success" @click="hideAskedSalarieAppointmentModal">Fermer</b-button>
                </div>
              </b-modal>

              <loading
                :active.sync="isLoading"
                :can-cancel="false"
                :is-full-page="true"
                color="#BB3634">
              </loading>

              <b-tabs v-model="tabsIndex" content-class="mt-3" active-nav-item-class="font-weight-bold text-primary">

                <!-- APPOINTMENT -->
                <b-tab v-if="salarie.state == '5BRI' || salarie.state == '6RDV'">
                  <template slot="title">
                    <span class="h4"> Rendez-vous</span>
                  </template>
                  <div class="row mt-3">
                    <div class="col-12">
                      <h2 style="font-size: 1.3em; margin-bottom: 0px;">Prendre votre rendez-vous avec un conseiller Audiens </h2>
                      <em class="text-secondary">Vous pouvez faire une demande de rendez-vous téléphonique directement en ligne.
                        Votre conseiller Audiens vous contactera alors pour convenir d'une date d'entretien téléphonique.</em>
                    </div>
                  </div>

                  <div class="row mt-3" v-if="salarie.state == '5BRI' || salarie.state == '6RDV'">
                    <div class="col-12">
                      <salarieAppointmentComponent />
                    </div>
                  </div>
                </b-tab>

                <!--BRI -->
                <b-tab v-if="salarie.state == '4VALIDATED' || salarie.state == '5BRI' || salarie.state == '6RDV'">
                  <template slot="title">
                    <span class="h4"> Bilan retraite individuel</span>
                  </template>
                  <div v-if="salarie.state == '5BRI' || salarie.state == '6RDV'" class="row text-center">
                    <div class="col-12">
                      <strong style="font-size: 1.3em;"> Votre bilan retraite individuel a été mis à disposition par votre conseiller Audiens</strong>
                      <salarieBRIComponent />

                      <hr>

                      <strong style="font-size: 1.3em;">
                      Vous pouvez maintenant prendre rendez-vous avec un conseiller Audiens </strong> <br>
                      <b-button
                        variant="outline-success" class="mt-2" size="lg"
                        @click='goToAppointmentTab'>
                        Cliquez ici pour prendre rendez-vous avec un conseiller Audiens
                      </b-button>
                    </div>
                  </div>



                </b-tab>

                <b-tab >
                  <template slot="title">
                    <span class="h4">Dossier</span>
                  </template>

                  <div v-if="salarie.state == '4VALIDATED' || salarie.state == '5BRI' || salarie.state == '6RDV'" class="row text-center">
                    <div class="col-12">
                      <strong style="font-size: 1.3em;"> Votre dossier a été validé par votre conseiller Audiens </strong> <br>
                      <b-button
                        variant="outline-success" class="mt-2" size="lg"
                        @click='goToBRITab'>
                        Cliquez ici pour accéder à votre bilan retraite individuel
                      </b-button>
                    </div>
                  </div>
                  <div v-else-if="salarie.state == '2ONGOING'" class="text-center">
                  </div>
                  <div v-else-if="!hasFilledAllInformation"
                       class="text-center">
                    <h2 class="text-secondary"> Veuillez renseigner toutes vos informations (dossier et carte d'identité) pour
                    pouvoir faire une demande de validation finale de votre dossier.</h2>
                  </div>
                  <div v-else class="row">
                    <div class="col-sm-12">
                      <b-button
                        block :variant="askSalarieValidationButtonStyle" class="mt-2" size="lg"
                        :disabled="askSalarieValidationInProcess"
                        @click='askSalarieValidation'>
                          <b-spinner small label="update EtatCivil Spinner" v-if="askSalarieValidationInProcess"></b-spinner> {{ askSalarieValidationButtonText }}
                      </b-button>
                    </div>
                  </div>


                  <hr v-if="salarie.state == '4VALIDATED' || salarie.state == '5BRI' || salarie.state == '6RDV'">

                  <div class="row mt-4">
                    <div class="col-12">
                      <strong style="font-size: 1.3em;"> Statut de votre dossier  :
                        <span v-if="salarie.state == '1CREATED'" class="text-primary" style="display: inline-flex; vertical-align: middle;">
                          <v-icon name="edit" scale="1.5"/>
                          <span class="ml-1">Dossier à réaliser puis demande de validation à faire</span>
                        </span>
                        <span v-if="salarie.state == '2ONGOING'" class="text-secondary" style="display: inline-flex; vertical-align: middle;">
                          <v-icon name="dot-circle" scale="1.4"/>
                          <span class="ml-1">En cours de validation par le conseiller Audiens</span>
                        </span>
                        <span v-if="salarie.state == '3REFUSED'" class="text-danger" style="display: inline-flex; vertical-align: middle;">
                          <v-icon name="times-circle" scale="1.4"/>
                          <span class="ml-1">Refusé par le conseiller Audiens, veuillez corriger vos informations et refaire une demande</span>
                        </span>
                        <span v-if="salarie.state == '4VALIDATED' || salarie.state == '5BRI' || salarie.state == '6RDV'" class="text-success" style="display: inline-flex; vertical-align: middle;">
                          <v-icon name="check-circle" scale="1.4"/>
                          <span class="ml-1">Validé par le conseiller Audiens</span>
                        </span>
                      </strong>
                    </div>
                  </div>
                  <div v-if="salarie.state == '3REFUSED'" class="row">
                    <div class="col-12">
                      <strong> Raisons du refus :</strong> <em> "{{ salarie.refused_message}}" </em>
                    </div>
                  </div>

                  <b-form class="mt-3">
                    <div class="row">
                      <div class="col-sm-6">
                        <b-form-group id="social_security-group" label="Numéro de Sécurité sociale" label-for="social_security-input">
                          <b-form-input
                            v-model.trim="socialSecurity" type="text"
                            maxlength="13"
                            placeholder="XXXXXXXXXXXXX"
                            id="social_security-input"
                            @input="$v.socialSecurity.$touch()"
                            :state="$v.socialSecurity.$dirty ? !$v.socialSecurity.$error : null"
                            :disabled="stopEditSalarieInformationForm">
                          </b-form-input>
                          <div class="form-error" v-if="!$v.socialSecurity.required">Renseignez un numéro de sécurité social</div>
                          <div class="form-error" v-if="!$v.socialSecurity.minLength || !$v.socialSecurity.maxLength ">Votre numéro de sécurité sociale doit faire exactement 13 chiffres</div>
                          <div class="form-error" v-if="!$v.socialSecurity.numeric">Votre numéro de sécurité sociale ne peut contenir que des chiffres</div>

                        </b-form-group>
                      </div>
                      <div class="col-sm-6">
                        <b-form-group id="nb_children-group" label="Nombre d'enfants" label-for="nb_children-input">
                          <b-form-input
                            v-model.trim="nbChildren" type="number"
                            id="nb_children-input"
                            min='0'
                            max='30'
                            @blur="nbChildren = Math.max(Math.min(Math.round(nbChildren), 30), 0)"
                            @input="$v.nbChildren.$touch()"
                            :state="$v.nbChildren.$dirty ? !$v.nbChildren.$error : null"
                            :disabled="stopEditSalarieInformationForm">
                          </b-form-input>
                          <div class="form-error" v-if="!$v.nbChildren.required">Renseignez votre nombre d'enfants</div>
                          <div class="form-error" v-if="!$v.nbChildren.minValue">Votre nombre d'enfants doit être supérieur à 0</div>
                        </b-form-group>
                      </div>
                    </div>

                    <b-form-group id="is_service_militaire-group" label="Avez-vous effectué votre service militaire ?"
                      label-for="is_service_militaire-input">
                      <b-form-checkbox v-model="isServiceMilitaire" name="is_service_militaire-input" switch :disabled="stopEditSalarieInformationForm">
                        <booleanComponent v-bind:value="isServiceMilitaire" />
                      </b-form-checkbox>
                    </b-form-group>

                    <div class="row" v-if="isServiceMilitaire">
                      <div class="col-sm-6">
                        <b-form-group id="beginning_service_militaire_date-group" label="Date de début du service militaire" label-for="beginning_service_militaire_date-input">
                            <b-form-input
                              v-model.trim="beginningServiceMilitaire" type="date"
                              id="beginning_service_militaire_date-input"
                              :disabled="stopEditSalarieInformationForm"
                              @input="$v.beginningServiceMilitaire.$touch()"
                              :state="$v.beginningServiceMilitaire.$dirty ? !$v.beginningServiceMilitaire.$error : null">
                            </b-form-input>
                            <div v-if="$browserDetect.isIE || $browserDetect.isSafari" class="text-info">
                              <small>Vous utilisez Internet Explorer ou Safari, veuillez entrer votre
                              date au format AAAA-MM-DD (Par exemple 1975-11-03)</small>
                            </div>
                            <div class="form-error" v-if="!$v.beginningServiceMilitaire.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</div>
                            <div class="form-error" v-if="!$v.beginningServiceMilitaire.required && !stopEditSalarieInformationForm">Veuillez renseigner votre date de début de service militaire</div>
                        </b-form-group>
                      </div>
                      <div class="col-sm-6">
                        <b-form-group id="ending_service_militaire_date-group" label="Date de fin du service militaire" label-for="ending_service_militaire_date-input">
                            <b-form-input
                              v-model.trim="endingServiceMilitaire" type="date"
                              id="ending_service_militaire_date-input"
                              :disabled="stopEditSalarieInformationForm"
                              @input="$v.endingServiceMilitaire.$touch()"
                              :state="$v.endingServiceMilitaire.$dirty ? !$v.endingServiceMilitaire.$error : null">
                            </b-form-input>
                            <div v-if="$browserDetect.isIE || $browserDetect.isSafari" class="text-info">
                              <small>Vous utilisez Internet Explorer ou Safari, veuillez entrer votre
                              date au format AAAA-MM-DD (Par exemple 1975-11-03)</small>
                            </div>
                            <div class="form-error" v-if="!$v.endingServiceMilitaire.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</div>
                            <div class="form-error" v-if="!$v.endingServiceMilitaire.required && !stopEditSalarieInformationForm">Veuillez renseigner votre date de fin de service militaire</div>
                        </b-form-group>
                      </div>
                    </div>

                    <b-form-group id="is_activity_abroad-group" label="Avez-vous effectué une activité professionnelle à l'étranger et/ou en Outre-Mer?" label-for="is_activity_abroad-input">
                      <b-form-checkbox v-model="isActivityAbroad" name="is_activity_abroad-input" switch :disabled="stopEditSalarieInformationForm">
                        <booleanComponent v-bind:value="isActivityAbroad" />
                      </b-form-checkbox>
                      <b-form-textarea
                        v-if="isActivityAbroad"
                        v-model.trim="informationActivityAbroad" type="text"
                        class="mt-2"
                        maxlength="500"
                        placeholder="Enumérez le ou les pays concerné(s) ainsi que la durée de chacune de vos activités"
                        id="information_activity_abroad-input"
                        @input="$v.informationActivityAbroad.$touch()"
                        :state="$v.informationActivityAbroad.$dirty ? !$v.informationActivityAbroad.$error : null"
                        :disabled="stopEditSalarieInformationForm">
                      </b-form-textarea>
                      <div class="form-error" v-if="!$v.informationActivityAbroad.required">Renseignez vos informations</div>
                      <div class="form-error" v-if="!$v.informationActivityAbroad.maxLength">Vos informations ne peuvent dépasser 500 caractères</div>
                    </b-form-group>

                    <b-form-group id="is_demande_rachat_trimestres-group" label="Souhaitez-vous faire une demande de rachat de trimestres ?" label-for="is_demande_rachat_trimestres-input">
                      <b-form-checkbox v-model="isDemandeRachatTrimestre" name="is_demande_rachat_trimestres-input" switch :disabled="stopEditSalarieInformationForm">
                        <booleanComponent v-bind:value="isDemandeRachatTrimestre" />
                      </b-form-checkbox>
                      <b-form-textarea
                        v-if="isDemandeRachatTrimestre"
                        v-model="rachatTrimestreInformation" type="text"
                        class="mt-2"
                        maxlength="500"
                        placeholder="Précisez le nombre de trimestres et les années concernées"
                        id="rachat_trimestre_information-input"
                        @input="$v.rachatTrimestreInformation.$touch()"
                        :state="$v.rachatTrimestreInformation.$dirty ? !$v.rachatTrimestreInformation.$error : null"
                        :disabled="stopEditSalarieInformationForm">
                      </b-form-textarea>
                      <div class="form-error" v-if="!$v.rachatTrimestreInformation.required">Renseignez vos informations</div>
                      <div class="form-error" v-if="!$v.rachatTrimestreInformation.maxLength">Vos informations ne peuvent dépasser 500 caractères</div>
                    </b-form-group>

                    <div v-if="salarie.state == '4VALIDATED' || salarie.state == '5BRI' || salarie.state == '6RDV'" class="text-center">
                      <h2 class="text-success"> Votre dossier a déjà été validé. </h2>
                      <contactComponent />
                    </div>
                    <div v-else class="row">
                      <div class="col-sm-12">
                        <b-button
                          block :variant="updateEtatCivilButtonStyle" class="mt-2"
                          :disabled="updateEtatCivilInProcess || $v.socialSecurity.$invalid || $v.nbChildren.$invalid || $v.isServiceMilitaire.$invalid || $v.beginningServiceMilitaire.$invalid || $v.endingServiceMilitaire.$invalid || $v.isActivityAbroad.$invalid || $v.informationActivityAbroad.$invalid || $v.isDemandeRachatTrimestre.$invalid || $v.rachatTrimestreInformation.$invalid"
                          @click='updateEtatCivil'>
                            <b-spinner small label="update EtatCivil Spinner" v-if="updateEtatCivilInProcess"></b-spinner> {{ updateEtatCivilButtonText }}
                        </b-button>
                        <em v-if="salarie.state == '2ONGOING'" class="text-info">
                          Attention, <strong> votre dossier est déjà en cours de validation </strong>
                          si vous le remettez à jour, la processus de demande de validation sera remis à zéro
                        </em>
                      </div>
                    </div>

                  </b-form>
                </b-tab>

                <b-tab>
                  <template slot="title">
                    <span class="h4" > Documents </span>
                  </template>

                  <div v-if="salarie.state == '4VALIDATED' || salarie.state == '5BRI' || salarie.state == '6RDV'" class="row text-center">
                    <div class="col-12">
                      <strong style="font-size: 1.3em;"> Votre dossier a été validé par votre conseiller Audiens </strong> <br>
                      <b-button
                        variant="outline-success" class="mt-2" size="lg"
                        @click='goToBRITab'>
                        Cliquez ici pour accéder à votre bilan retraite individuel
                      </b-button>
                    </div>
                  </div>
                  <hr v-if="salarie.state == '4VALIDATED' || salarie.state == '5BRI' || salarie.state == '6RDV'">

                  <div class="row">
                    <div class="col-12">
                      <strong style="font-size: 1.3em;"> Statut de votre dossier  :
                        <span v-if="salarie.state == '1CREATED'" class="text-primary" style="display: inline-flex; vertical-align: middle;">
                          <v-icon name="edit" scale="1.5"/>
                          <span class="ml-1">Dossier à réaliser puis demande de validation à faire</span>
                        </span>
                        <span v-if="salarie.state == '2ONGOING'" class="text-secondary" style="display: inline-flex; vertical-align: middle;">
                          <v-icon name="dot-circle" scale="1.4"/>
                          <span class="ml-1">En cours de validation par le conseiller Audiens</span>
                        </span>
                        <span v-if="salarie.state == '3REFUSED'" class="text-danger" style="display: inline-flex; vertical-align: middle;">
                          <v-icon name="times-circle" scale="1.4"/>
                          <span class="ml-1">Refusé par le conseiller Audiens, veuillez corriger vos informations et refaire une demande</span>
                        </span>
                        <span v-if="salarie.state == '4VALIDATED' || salarie.state == '5BRI' || salarie.state == '6RDV'" class="text-success" style="display: inline-flex; vertical-align: middle;">
                          <v-icon name="check-circle" scale="1.4"/>
                          <span class="ml-1">Validé par le conseiller Audiens</span>
                        </span>
                      </strong>
                    </div>
                  </div>
                  <div v-if="salarie.state == '3REFUSED'" class="row">
                    <div class="col-12">
                      <strong> Raisons du refus :</strong> <em> "{{ salarie.refused_message}}" </em>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-12">
                      <h2 style="font-size: 1.3em; margin-bottom: 0px;"> Votre document d'identité </h2>
                      <em class="text-secondary">Il s'agit de votre carte d'identité <strong>recto verso</strong> ou de votre passeport (page avec votre photo) </em>
                    </div>
                  </div>


                  <!--  ID CARD -->
                  <div class="row mt-3 text-center">
                    <div class="col-sm-4 align-self-center" v-if="id_card.file_name">
                      <span> Votre document actuel </span> <br>
                      <a href="#" @click="getProtectedDocument(id_card.id, id_card.file_name)">Visualiser votre document</a> <br>
                      <em><small> ( {{ sizeFormatter(id_card.file_size) }} )</small> </em>
                    </div>
                    <div class="col-sm-4 align-self-center" v-else>
                      <em>Aucun document d'identité encore chargé </em>
                    </div>

                    <div v-if="salarie.state == '4VALIDATED' || salarie.state == '5BRI' || salarie.state == '6RDV'" class="col-sm-8 align-self-center text-center">
                      <strong class="text-success" style="font-size: 1.2em;"> Votre document d'identité a été validé. </strong>
                    </div>
                    <div v-else class="col-sm-8 align-self-center text-center">
                      <b-button
                        block variant="outline-secondary" class="mt-2"
                        @click="showUploadDocumentModal(id_card.id, 'ID')">
                          Charger votre document d'identité
                      </b-button>
                      <small v-if="salarie.state == '2ONGOING'" class="text-info">
                        <strong>Attention, votre dossier est en cours de validation.</strong> <br>
                        Si vous le chargez à nouveau le processus de demande de validation sera remis à zéro
                      </small>
                    </div>
                  </div>


                  <div v-if="salarie.state == '4VALIDATED' || salarie.state == '5BRI' || salarie.state == '6RDV'" class="text-center">
                    <h2 class="text-success"> Votre dossier a déjà été validé. </h2>
                    <hr>
                    <contactComponent />
                  </div>
                  <div v-else-if="salarie.state == '2ONGOING'" class="text-center">
                    <h2 class="text-primary"> Votre dossier est en cours de validation. </h2>
                    <hr>
                    <contactComponent />
                  </div>
                  <div v-else-if="!hasFilledAllInformation"
                       class="text-center">
                    <h2 class="text-secondary"> Veuillez renseigner toutes vos informations (dossier et carte d'identité) pour
                    pouvoir faire une demande de validation finale de votre dossier.</h2>
                    <hr>
                    <contactComponent />
                  </div>
                  <div v-else class="row">
                    <div class="col-sm-12">
                      <b-button
                        block :variant="askSalarieValidationButtonStyle" class="mt-2" size="lg"
                        :disabled="askSalarieValidationInProcess"
                        @click='askSalarieValidation'>
                          <b-spinner small label="update EtatCivil Spinner" v-if="askSalarieValidationInProcess"></b-spinner> {{ askSalarieValidationButtonText }}
                      </b-button>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { APISalarieConnected } from '@/api/APISalarieConnected'
import { required, minLength, maxLength, numeric, minValue, requiredIf } from 'vuelidate/lib/validators'
import booleanComponent from '@/components/booleanComponent'
import contactComponent from '@/components/contactComponent'
import { fileOperations } from '@/mixins/utilMixins'
import { file_size_validation, file_extension_validation, isDate } from '@/validators/validators'

import formTitleComponent from '@/components/formTitleComponent'

import salarieBRIComponent from '@/components/SalarieBRIComponent'
import salarieAppointmentComponent from '@/components/SalarieAppointmentComponent'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';


const apiSalarieConnectedService = new APISalarieConnected()

export default {
  name: 'SalarieDashboard',
  components: {
    booleanComponent,
    contactComponent,
    formTitleComponent,
    salarieBRIComponent,
    salarieAppointmentComponent,
    Loading
  },
  mixins: [
    validationMixin,
    fileOperations
  ],
  data: function () {
    return {

      tabsIndex: 0,
      // ------- Salarie --------
      salarie: '',
      salarieLoading: false,

      // ------ Etat civil ------
      etat_civil: '',
      etatCivilLoading: false,

      socialSecurity: '',
      nbChildren: '',
      isServiceMilitaire: false,
      beginningServiceMilitaire: '',
      endingServiceMilitaire: '',
      isActivityAbroad: false,
      informationActivityAbroad: '',
      isDemandeRachatTrimestre: false,
      rachatTrimestreInformation: '',

      updateEtatCivilButtonText: 'Mettre à jour votre dossier',
      updateEtatCivilButtonStyle: 'outline-primary',
      updateEtatCivilInProcess: false,

      askSalarieValidationButtonText: 'Demander la validation finale de votre dossier',
      askSalarieValidationButtonStyle: "outline-success",
      askSalarieValidationInProcess: false,

      // ------ Document --------
      handleDocumentID: 0,
      handleDocumentType: '',

      documentFile: [],
      uploadDocumentButtonText: 'Charger votre document',
      uploadDocumentButtonStyle: 'outline-primary',
      uploadDocumentInProcess: false,

      // ------ ID Card ------
      id_card: '',
      idCardLoading: false,

      // ------ Appointment ----
      askSalarieAppointmentButtonText: "Faire une demande d'entretien téléphonique",
      askSalarieAppointmentButtonStyle: "outline-success",
      askSalarieAppointmentInProcess: false,

    }
  },
  computed: {
    isAuthenticated () {
      return this.$store.getters.isAuthenticated
    },
    token () {
      return this.$store.state.auth.token
    },
    stopEditSalarieInformationForm () {
      if ( this.salarie.state=='4VALIDATED' || this.salarie.state == '5BRI' || this.salarie.state == '6RDV'){
        return true
      }
      return false
    },
    hasFilledAllInformation () {
      if (!this.etat_civil.has_been_updated || !this.id_card.has_been_updated) {
        return false
      }
      return true
    },
    isLoading () {
      if (this.salarieLoading || this.etatCivilLoading || this.idCardLoading || this.uploadDocumentInProcess) {
        return true
      }
      return false
    }
  },
  created: function() {
    this.getConnectedSalarie()
    this.getEtatCivil()
    this.getDocument('ID')
  },
  mounted: function() {
    if (this.salarie.state == '6RDV' || this.salarie.state == '5BRI' || this.salarie.state == '4VALIDATED') {
      this.tabsIndex = 0
    }
  },
  validations: {
    socialSecurity: {
      minLength: minLength(13),
      maxLength: maxLength(13),
      numeric,
      required
    },
    nbChildren: {
      required,
      minValue: minValue(0)
    },
    isServiceMilitaire: {
      required,
    },
    beginningServiceMilitaire: {
      required: requiredIf(function () {
        return this.isServiceMilitaire
      }),
      validDate(value) {
        return isDate(value)
      }
    },
    endingServiceMilitaire: {
      required: requiredIf(function () {
        return this.isServiceMilitaire
      }),
      validDate(value) {
        return isDate(value)
      }
    },
    isActivityAbroad: {
      required,
    },
    informationActivityAbroad: {
      required: requiredIf(function () {
        return this.isActivityAbroad
      }),
      maxLength: maxLength(500),
    },
    isDemandeRachatTrimestre: {
      required,
    },
    rachatTrimestreInformation: {
      required: requiredIf(function () {
        return this.isDemandeRachatTrimestre
      }),
      maxLength: maxLength(500),
    },

    documentFile: {
      required,
      file_size_validation,
      file_extension_validation,

      name :{
        maxLength: maxLength(100),
        minLength: minLength(2)
      }
    },
  },
  methods: {
    // ---------------------- Global function -----------------
    hideDocumentUploadedModal () {
      this.$refs.documentUploadedModal.hide()
    },
    hideAskSalarieValidationdModal () {
      this.$refs.askSalarieValidationdModal.hide()
    },
    hideGlobalAPILoadingErrorModal () {
      this.$refs.globalAPILoadingErrorModal.hide()
    },
    hideAskedSalarieAppointmentModal () {
      this.$refs.askedSalarieAppointmentModal.hide()
    },

    // ---------------------- SALARIE -----------------------
    getConnectedSalarie () {
      this.salarieLoading = true
      apiSalarieConnectedService.getConnectedSalarie(this.token)
        .then((result) => {
          this.salarie = result.data
        })
        .catch(() => {
          this.$refs.globalAPILoadingErrorModal.show()
        })
        .finally(() => {
          this.salarieLoading = false
        })
    },
    // ----------------------- ETAT CIVIL --------------------
    getEtatCivil () {
      this.etatCivilLoading = true
      apiSalarieConnectedService.getEtatCivil(this.token)
        .then((result) => {
          this.etat_civil = result.data

          this.socialSecurity = result.data['social_security']
          this.nbChildren = result.data['nb_children']
          this.isServiceMilitaire = result.data['is_service_militaire']
          this.beginningServiceMilitaire = result.data['beginning_service_militaire']
          this.endingServiceMilitaire = result.data['ending_service_militaire']
          this.isActivityAbroad = result.data['is_activity_abroad']
          this.informationActivityAbroad = result.data['information_activity_abroad']
          this.isDemandeRachatTrimestre = result.data['is_demande_rachat_trimestres']
          this.rachatTrimestreInformation = result.data['rachat_trimestre_information']
        })
        .catch(() => {
          this.$refs.globalAPILoadingErrorModal.show()
        })
        .finally(() => {
          this.etatCivilLoading = false
        })
    },
    updateEtatCivil () {
      this.updateEtatCivilButtonText = "Mise à jour en cours"
      this.updateEtatCivilButtonStyle = "secondary"
      this.updateEtatCivilInProcess = true
      apiSalarieConnectedService.updateEtatCivil(
        this.token, this.socialSecurity, this.nbChildren, this.isServiceMilitaire,
        this.beginningServiceMilitaire, this.endingServiceMilitaire,
        this.isActivityAbroad, this.informationActivityAbroad, this.isDemandeRachatTrimestre,
        this.rachatTrimestreInformation)
        .then(() => {
          this.$refs.etatCivilUpdatedModal.show()
          this.tabsIndex ++
          this.getEtatCivil()
          this.getConnectedSalarie()
        })
        .catch(() => {
          this.$refs.globalAPILoadingErrorModal.show()
        })
        .finally(() => {
          this.updateEtatCivilButtonText = "Mettre à jour votre dossier"
          this.updateEtatCivilButtonStyle = "outline-primary"
          this.updateEtatCivilInProcess = false
        })
    },
    askSalarieValidation () {
      this.askSalarieValidationButtonText = "Demande de validation en cours"
      this.askSalarieValidationButtonStyle = "secondary"
      this.askSalarieValidationInProcess = true

      apiSalarieConnectedService.askSalarieValidation(this.token)
        .then(() => {
          this.$refs.askSalarieValidationdModal.show()
          this.getConnectedSalarie()
        })
        .catch(() => {
          this.$refs.globalAPILoadingErrorModal.show()
        })
        .finally(() => {
          this.askSalarieValidationButtonText = "Demander la validation finale de votre dossier"
          this.askSalarieValidationButtonStyle = "outline-success"
          this.askSalarieValidationInProcess = false
        })

    },

    hideEtatCivilUpdatedModalModal () {
      this.$refs.etatCivilUpdatedModal.hide()
    },

    // --------------- Global Documents --------------------
    getDocument (type) {
      // type is a string : ID
      this.documentLoading = true
      apiSalarieConnectedService.getDocument(this.token, type)
        .then((result) => {
          if (type == 'ID') {
            this.id_card = result.data
          }
        })
        .catch(() => {
          this.$refs.globalAPILoadingErrorModal.show()
        })
        .finally(() => {
          this.documentLoading = false
        })
    },

    getProtectedDocument (document_id, document_name) {
      apiSalarieConnectedService.getOwnProtectedDocument(this.token, document_id)
      .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', document_name); //or any other extension
          document.body.appendChild(link);
          link.click();
        }, () => {
          this.$refs.globalAPILoadingErrorModal.show()
        })
    },

    // ------------------ DOCUMENT ---------------------

    showUploadDocumentModal (handleDocumentID, handleDocumentType ) {
      this.handleDocumentID = handleDocumentID
      this.handleDocumentType = handleDocumentType
      this.$refs.uploadDocumentModal.show()
    },

    uploadDocument () {
      this.uploadDocumentButtonText = "Envoi en cours"
      this.uploadDocumentButtonStyle = "secondary"
      this.uploadDocumentInProcess = true
      apiSalarieConnectedService.uploadDocument(
        this.token, this.handleDocumentID, this.documentFile)
        .then(() => {
          this.$refs.documentUploadedModal.show()
          this.getDocument(this.handleDocumentType)
          this.getConnectedSalarie()
        })
        .catch(() => {
          this.$refs.globalAPILoadingErrorModal.show()
        })
        .finally(() => {
          this.$refs.uploadDocumentModal.hide()
          this.uploadDocumentButtonText = "Charger votre document"
          this.uploadDocumentButtonStyle = "outline-primary"
          this.uploadDocumentInProcess = false
        })
    },


    // ----------- APPOINTMENT -------------
    askSalarieAppointment () {
      this.askSalarieAppointmentButtonText = "Demande de rendez-vous en cours"
      this.askSalarieAppointmentButtonStyle = "secondary"
      this.askSalarieAppointmentInProcess = true

      apiSalarieConnectedService.askSalarieAppointment(this.token)
        .then(() => {
          this.$refs.askedSalarieAppointmentModal.show()
          this.getConnectedSalarie()
        })
        .catch(() => {
          this.$refs.globalAPILoadingErrorModal.show()
        })
        .finally(() => {
          this.askSalarieAppointmentButtonText = "Faire une demande d'entretien téléphonique"
          this.askSalarieAppointmentButtonStyle = "outline-success"
          this.askSalarieAppointmentInProcess = false
        })

    },

    goToBRITab () {
      if (this.salarie.state == '4VALIDATED') {
        this.tabsIndex = 0
      }
      else {
        this.tabsIndex = 1
      }
    },
    goToAppointmentTab () {
      this.tabsIndex = 0
    },
    goToEtatCivil () {
      this.tabsIndex ++
    },
    goToDocuments () {
      this.tabsIndex += 2
    }
  }
}
</script>
<style>
.option-button {
    height:100%;
}

.media-object {
    height: 100px;
}
</style>
