<template>
  <div class="salarieAppointmentComponent">
    <!-- CREATE APPOINTMENT MODAL -->
    <b-modal ref="createAppointmentModal" hide-footer>
      <template v-slot:modal-title>
        <strong> Prendre rendez-vous avec un conseiller Audiens </strong>
      </template>
      <div>
        <b-form>
          <div class="row">
            <div class="col-12">
              <strong> Votre date : {{ $date(createAppointmentDate).format("DD/MM/YYYY") }}</strong>
            </div>
          </div>
          <b-form-group class="mt-2" id="create-accompagnement-schedules-group" label="Plages horaires disponibles" label-for="create-accompagnement-schedules-input">
            <b-form-select
              v-model="createAppointmentStart" :options="appointmentSchedulesOptions"
              id="create-accompagnement-schedules-input"
              @input="$v.createAppointmentStart.$touch()"
              :state="$v.createAppointmentStart.$dirty ? !$v.createAppointmentStart.$error : null">
            </b-form-select>
          </b-form-group>

          <b-button
            block :variant="createAppointmentButtonStyle" class="mt-2"
            @click='createAppointment'
            :disabled="$v.createAppointmentStart.$invalid || createAppointmentInProcess">
              <b-spinner small label="create appointment Spinner" v-if="createAppointmentInProcess"></b-spinner> {{ createAppointmentButtonText }}
          </b-button>
        </b-form>
      </div>
    </b-modal>

    <!-- CREATE APPOINTMENT ERROR MODAL -->
    <b-modal ref="createAppointmentErrorModal" hide-footer title="Erreur lors de la validation de votre rendez-vous">
      <div class="text-center">
        <p>Une erreur s'est produite lors de la validation de votre rendez-vous, veuillez vérifier les données renseignées ou nous
        contacter via le formulaire de contact.</p>
        <b-button class="mt-3" variant="outline-success" @click="hideCreateAppointmentErrorModal">Fermer</b-button>
      </div>
    </b-modal>


    <!-- CREATED APPOINTMENT MODAL -->
    <b-modal ref="createdAppointmentModal" hide-footer title="Votre rendez-vous a bien été réservé">
      <div class="text-center">
        <p><strong>Votre rendez-vous a bien été reservé.</strong> <br>
           Vous pouvez modifier ou annuler votre rendez-vous jusqu'à 24h avant son heure de début.
         </p>
        <b-button class="mt-3" variant="outline-success" @click="hideCreatedAppointmentModal">Fermer</b-button>
      </div>
    </b-modal>

    <!-- MODIFY APPOINTMENT MODALS -->
    <!-- CHOOSE DATE APPOITNMENT MODAL -->
    <b-modal ref="modifyDateAppointmentModal" hide-footer size="lg">
      <template v-slot:modal-title>
        <strong> Choisissez une nouvelle date pour votre rendez-vous avec votre conseiller Audiens </strong>
      </template>
      <div class="row">
        <div class="col-12">
          <strong>Votre date actuelle : {{ $date(actualAppointment.day).format("DD/MM/YYYY") }}</strong>
          <b-button
            block variant='outline-primary' class="mt-2"
            @click='showModifyAppointmentModal(actualAppointment.day)'>
            Conserver votre date actuelle
          </b-button>
        </div>
      </div>
      <hr>
      <div>
        <strong>Modifier votre date de rendez-vous</strong>
        <b-table
          responsive class="mt-2 text-center"
          :items="appointmentsSlots"
          :fields="appointmentsSlotsFields">
          <template v-slot:cell(dates)="data">
            <strong class="thead-clickable" v-on:click="showModifyAppointmentModal(data.value)">
              {{ $date(data.value).format("DD/MM/YYYY") }}
            </strong>
          </template>
        </b-table>
      </div>
    </b-modal>

    <!-- MODIFY APPOINTMENT MODAL -->
    <b-modal ref="modifyAppointmentModal" hide-footer>
      <template v-slot:modal-title>
        <strong> Modifier votre rendez-vous avec votre conseiller Audiens </strong>
      </template>
      <div>
        <div class="row">
          <div class="col-12">
            <strong> Votre date : {{ $date(modifyAppointmentDate).format("DD/MM/YYYY") }}</strong>
          </div>
        </div>
        <b-form>
          <b-form-group class="mt-2" id="modify-accompagnement-schedules-group" label="Plages horaires disponibles" label-for="modify-accompagnement-schedules-input">
            <b-form-select
              v-model="modifyAppointmentStart" :options="appointmentSchedulesOptions"
              id="modify-accompagnement-schedules-input"
              @input="$v.modifyAppointmentStart.$touch()"
              :state="$v.modifyAppointmentStart.$dirty ? !$v.modifyAppointmentStart.$error : null">
            </b-form-select>
          </b-form-group>

          <b-button
            block :variant="modifyAppointmentButtonStyle" class="mt-2"
            @click='modifyAppointment'
            :disabled="$v.modifyAppointmentStart.$invalid || modifyAppointmentInProcess">
              <b-spinner small label="modify appointment Spinner" v-if="modifyAppointmentInProcess"></b-spinner> {{ modifyAppointmentButtonText }}
          </b-button>
        </b-form>
      </div>
    </b-modal>

    <!-- MODIFY APPOINTMENT ERROR MODAL -->
    <b-modal ref="modifyAppointmentErrorModal" hide-footer title="Erreur lors de la modification de votre rendez-vous">
      <div class="text-center">
        <p>Une erreur s'est produite lors de la modification de votre rendez-vous, veuillez vérifier les données renseignées ou nous
        contacter via le formulaire de contact.</p>
        <b-button class="mt-3" variant="outline-success" @click="hideModifyAppointmentErrorModal">Fermer</b-button>
      </div>
    </b-modal>

    <!-- MODIFIED APPOINTMENT  MODAL -->
    <b-modal ref="modifiedAppointmentModal" hide-footer title="Votre rendez-vous a bien été modifié">
      <div class="text-center">
        <p><strong>Votre rendez-vous a bien été modifié.</strong> <br>
           Vous pouvez modifier ou annuler votre rendez-vous jusqu'à 24h avant son heure de début.
         </p>
        <b-button class="mt-3" variant="outline-success" @click="hideModifiedAppointmentModal">Fermer</b-button>
      </div>
    </b-modal>

    <!-- DELETE APPOITMENT MODALS -->
    <!-- DELETE APPOINTMENT MODAL -->
    <b-modal ref="deleteAppointmentModal" hide-footer>
      <template v-slot:modal-title>
        <strong> Annuler votre rendez-vous avec votre conseiller Audiens </strong>
      </template>
      <div>
        <div class="row">
          <div class="col-12">
            <span> Votre rendez-vous : </span> <br>
            <strong>{{ $date(actualAppointment.day).format("DD/MM/YYYY") }} de {{ actualAppointment.start }} heure à {{ actualAppointment.end }} heures</strong>
          </div>
        </div>
        <b-button
          block :variant="deleteAppointmentButtonStyle" class="mt-3"
          @click='deleteAppointment'
          :disabled="deleteAppointmentInProcess">
            <b-spinner small label="delete appointment Spinner" v-if="deleteAppointmentInProcess"></b-spinner> {{ deleteAppointmentButtonText }}
        </b-button>
      </div>
    </b-modal>

    <!-- DELETE APPOINTMENT ERROR MODAL -->
    <b-modal ref="deleteAppointmentErrorModal" hide-footer title="Erreur lors de l'annulation de votre rendez-vous">
      <div class="text-center">
        <p>Une erreur s'est produite lors de l'annulation de votre rendez-vous, si l'erreur persiste, vous pouvez nous
        contacter via le formulaire de contact.</p>
        <b-button class="mt-3" variant="outline-success" @click="hideDeleteAppointmentErrorModal">Fermer</b-button>
      </div>
    </b-modal>


    <!-- DELETED APPOINTMENT MODAL -->
    <b-modal ref="deletedAppointmentModal" hide-footer title="Votre rendez-vous a bien été annulé">
      <div class="text-center">
        <p><strong>Votre rendez-vous a bien été annulé.</strong> <br>
           Vous pouvez maitenant reprendre un rendez-vous.
         </p>
        <b-button class="mt-2" variant="outline-success" @click="hideDeletedAppointmentModal">Fermer</b-button>
      </div>
    </b-modal>

    <div v-if="actualAppointment" class="mt-2 text-center">

      <p style="font-size: 1.3em;">
        <span> Votre rendez-vous est prévu le </span> <br>
        <strong> {{ $date(actualAppointment.day).format("DD/MM/YYYY") }} de {{ actualAppointment.start }} heure à {{ actualAppointment.end }} heures</strong>
      </p>
      <div class="row">
        <div class="col-sm-6">
          <b-button
            block variant="outline-primary" class="mt-2"
            @click="showModifyDateAppoinementModal">
              Modifier votre rendez-vous
          </b-button>
        </div>
        <div class="col-sm-6">
          <b-button
            block variant="outline-danger" class="mt-2"
            @click="showDeleteAppoinementModal">
              Annuler votre rendez-vous
          </b-button>
        </div>
      </div>
    </div>
    <div v-else class="mt-2 text-center">

      <strong> Vous n'avez pas encore reservé de rendez-vous. Cliquez sur une des dates disponibles pour
        réserver un rendez-vous avec un conseiller Audiens.
      </strong>
      <b-table
        responsive class="mt-2 text-center"
        :items="appointmentsSlots"
        :fields="appointmentsSlotsFields">
        <template v-slot:cell(dates)="data">
          <span class="thead-clickable" v-on:click="showCreateAppointmentModal(data.value)">
            {{  $date(data.value).format("DD/MM/YYYY") }}
          </span>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
const isoWeek = require('dayjs/plugin/weekday')
dayjs.extend(isoWeek)
import { validationMixin } from 'vuelidate'
import { APISalarieConnected } from '@/api/APISalarieConnected'
import { required, numeric, minValue, maxValue} from 'vuelidate/lib/validators'

const apiSalarieConnectedService = new APISalarieConnected()

export default {
  name: 'salarieAppointmentComponent',
  mixins: [
    validationMixin,
  ],
  data: function () {
    return {
      appointmentsSlots: [],
      appointmentsSlotsFields: [
        { key: 'dates', label: "Prochaines dates disponibles"}
      ],
      createAppointmentDate: '',
      createAppointmentStart: 0,
      defaultAppointmentSchedulesOptions: [
        { text: 'De 9h à 10h', value: 9 },
        { text: 'De 10h à 11h', value: 10 },
        { text: 'De 11h à 12h', value: 11 },
        { text: 'De 14h à 15h', value: 14 },
        { text: 'De 15h à 16h', value: 15 },
        { text: 'De 16h à 17h', value: 16 },
      ],
      appointmentSchedulesOptions: [],
      availablesSchedulesLoading: false,
      createAppointmentButtonText: 'Valider votre rendez-vous',
      createAppointmentButtonStyle: 'outline-success',
      createAppointmentInProcess: false,
      appointmentLoading: '',
      actualAppointment: '',
      modifyAppointmentDate: '',
      modifyAppointmentStart: '',
      modifyAppointmentButtonText: 'Modifier votre rendez-vous',
      modifyAppointmentButtonStyle: 'outline-primary',
      modifyAppointmentInProcess: false,
      deleteAppointmentButtonText: "Confirmer l'annulation de votre rendez-vous",
      deleteAppointmentButtonStyle: 'outline-danger',
      deleteAppointmentInProcess: false,

      isForbiddenDatesLoading: false
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    }
  },
  created: function() {
    this.getAppointment()
    this.createAppointmentSlots()
  },
  validations: {
    createAppointmentDate: {
      required
    },
    createAppointmentStart: {
      required,
      numeric,
      minValue: minValue(9),
      maxValue: maxValue(17)
    },
    modifyAppointmentDate: {
      required
    },
    modifyAppointmentStart: {
      required,
      numeric,
      minValue: minValue(9),
      maxValue: maxValue(17)
    },
  },
  methods: {
    // ---------------------- GET APPOINTMENT -----------------------
    getAppointment () {
      this.appointmentLoading = true
      apiSalarieConnectedService.getAppointment(this.token)
        .then((result) => {
          this.actualAppointment = result.data
        })
        .finally(() => {
          this.appointmentLoading = false
        })
    },
    // ----------------  CREATE ALL THE SLOTS -----------------
    createAppointmentSlots () {

      this.isForbiddenDatesLoading = true
      apiSalarieConnectedService.getForbiddenDates(this.token)
        .then((result) => {
          var forbidden_date_set = new Set()
          for (var i = 0; i < result.data.length; i++) {
            forbidden_date_set.add(result.data[i]['day'])
          }
          var today = dayjs()
          var tempMoment = today.add(1, 'week')
          while (this.appointmentsSlots.length < 20) {
            tempMoment = tempMoment.add(1, 'day')
            if (!forbidden_date_set.has(tempMoment.format('YYYY-MM-DD'))) {
              var loopMoment = tempMoment.clone()
              var weekDay = loopMoment.weekday()
              if (weekDay == 1 || weekDay == 2 || weekDay == 3 || weekDay == 4 || weekDay == 5) {
                this.appointmentsSlots.push(
                  {'dates': loopMoment.format('YYYY-MM-DD')}
                )
              }
            }
          }
        })
        .finally(() => {
          this.isForbiddenDatesLoading = false
        })
    },
    getAvailableSchedules (day) {
      this.availablesSchedulesLoading = true
      this.appointmentSchedulesOptions = []
      apiSalarieConnectedService.getAvailableSchedules(this.token, day)
        .then((result) => {
          for (var i = 0; i < this.defaultAppointmentSchedulesOptions.length; i++) {
            if (result.data.includes(this.defaultAppointmentSchedulesOptions[i].value)) {
              this.appointmentSchedulesOptions.push(this.defaultAppointmentSchedulesOptions[i])
            }
          }
        })
        .finally(() => {
          this.appointmentLoading = false
        })
    },
    // ----------------- CREATE APPOINTMENT ------------------
    showCreateAppointmentModal (day) {
      this.createAppointmentDate = day
      this.getAvailableSchedules(day)
      this.$refs.createAppointmentModal.show()
    },
    hideCreateAppointmentErrorModal () {
      this.$refs.createAppointmentErrorModal.hide()
    },
    hideCreatedAppointmentModal () {
      this.$refs.createdAppointmentModal.hide()
    },
    createAppointment () {
      this.createAppointmentInProcess = true
      this.createAppointmentButtonText = "Validation en cours"
      this.createAppointmentButtonStyle = 'secondary'
      apiSalarieConnectedService.createAppointment(this.token, this.createAppointmentDate, this.createAppointmentStart, this.createAppointmentStart + 1)
      .then(() => {
          this.getAppointment()
          this.$refs.createdAppointmentModal.show()
        }, () => {
          this.$refs.createAppointmentErrorModal.show()
        })
      .finally(() => {
        this.$refs.createAppointmentModal.hide()
        this.createAppointmentInProcess = false
        this.createAppointmentButtonText = "Valider votre rendez-vous"
        this.createAppointmentButtonStyle = 'outline-success'
      })
    },
    // ------------- MODIFY APPOINTMENT ---------------
    showModifyDateAppoinementModal () {
      this.$refs.modifyDateAppointmentModal.show()
    },
    showModifyAppointmentModal (day) {
      this.$refs.modifyDateAppointmentModal.hide()
      this.modifyAppointmentDate = day
      this.getAvailableSchedules(day)
      this.$refs.modifyAppointmentModal.show()
    },
    hideModifyAppointmentErrorModal () {
      this.$refs.modifyAppointmentErrorModal.hide()
    },
    hideModifiedAppointmentModal () {
      this.$refs.modifiedAppointmentModal.hide()
    },
    modifyAppointment () {
      this.modifyAppointmentInProcess = true
      this.modifyAppointmentButtonText = "Modification en cours"
      this.modifyAppointmentButtonStyle = 'secondary'
      apiSalarieConnectedService.modifyAppointment(this.token, this.modifyAppointmentDate, this.modifyAppointmentStart, this.modifyAppointmentStart + 1)
      .then(() => {
          this.$refs.modifiedAppointmentModal.show()
          this.getAppointment()
        }, () => {
          this.$refs.modifyAppointmentErrorModal.show()
        })
      .finally(() => {
        this.$refs.modifyAppointmentModal.hide()
        this.modifyAppointmentInProcess = false
        this.modifyAppointmentButtonText = "Modifier votre rendez-vous"
        this.modifyAppointmentButtonStyle = 'outline-primary'
      })
    },
    // ------------- DELETE APPOINTMENT ---------------
    showDeleteAppoinementModal () {
      this.$refs.deleteAppointmentModal.show()
    },
    hideDeleteAppointmentErrorModal () {
      this.$refs.deleteAppointmentErrorModal.hide()
    },
    hideDeletedAppointmentModal () {
      this.$refs.deletedAppointmentModal.hide()
    },
    deleteAppointment () {
      this.deleteAppointmentInProcess = true
      this.deleteAppointmentButtonText = "Annulation en cours"
      this.deleteAppointmentButtonStyle = 'secondary'
      apiSalarieConnectedService.deleteAppointment(this.token)
      .then(() => {
          this.$refs.deletedAppointmentModal.show()
          this.getAppointment()
        }, () => {
          this.$refs.deleteAppointmentErrorModal.show()
        })
      .finally(() => {
        this.$refs.deleteAppointmentModal.hide()
        this.deleteAppointmentInProcess = false
        this.deleteAppointmentButtonText = "Confirmer l'annulation de votre rendez-vous"
        this.deleteAppointmentButtonStyle = 'outline-danger'
      })
    },
  }
}
</script>

<style>
.thead-clickable {
  cursor: pointer;
}
.thead-clickable:hover{
  font-size: 1.1em;
  font-weight: bold;
  color: blue !important;
}
</style>
