import axios from 'axios'
import { apiBaseUrl } from '@/variables/localVariables'
import store from '@/store/store'
import router from '@/router'

const axios_instance = axios.create({
  baseURL:apiBaseUrl,
});

var isRefreshing = false

//Add a response interceptor
axios_instance.interceptors.response.use(
  function (response) {
    store.dispatch('AUTH_INSPECT')
      .catch(() => {
        store.commit('AUTH_LOGOUT')
        router.push('/login')
      })
    return response
  },
  function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && originalRequest.url === apiBaseUrl +'/auth/token/refresh') {
     router.push('/login');
     return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true
        originalRequest._retry = true
        const refreshToken = JSON.parse(store.state.auth.token).refresh

        return axios_instance.post(apiBaseUrl +'/auth/token/refresh', {"refresh": refreshToken})
         .then(res => {
           if (res.status === 200) {
             store.commit('AUTH_REFRESH', res.data)
             originalRequest.headers['Authorization'] = 'Bearer ' + res.data.access
             return axios_instance(originalRequest)
           }
           else {
             store.commit('AUTH_LOGOUT')
             router.push('/login')
           }
         })
         .catch(() => {
           store.commit('AUTH_LOGOUT')
           router.push('/login')
         })
         .finally(() => {
           isRefreshing = false
         })
     }
     originalRequest.headers['Authorization'] = 'Bearer ' + JSON.parse(store.state.auth.token).access
     return axios_instance(originalRequest)
   }
   return Promise.reject(error)
});

export class APISalarieConnected {

  getConnectedSalarie(token) {
    const url = apiBaseUrl + `/connected-salarie/`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  updateSalarieProfil (token, first_name, last_name, birthdate, phone_number,
    professional_category, employeur, muna) {
    const url = apiBaseUrl + `/connected-salarie/`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    var data = {
      'first_name': first_name,
      'last_name': last_name,
      'birthdate': birthdate,
      'phone_number': phone_number,
      'professional_category': professional_category,
      'employeur': employeur,
      'muna': muna,
    }

    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  resetSalariePassword (token, old_password, new_password, new_password_confirmed) {
    const url = apiBaseUrl + `/reset-password/`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    var data = {
      'old_password': old_password,
      'new_password': new_password,
      'new_password_confirmed': new_password_confirmed,
    }

    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  getEtatCivil (token) {
    const url = apiBaseUrl + `/etat-civil/`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  updateEtatCivil (token, social_security ,nb_children, is_service_militaire,
    beginning_service_militaire, ending_service_militaire, is_activity_abroad,
    information_activity_abroad,
    is_demande_rachat_trimestres, rachat_trimestre_information) {
    const url = apiBaseUrl + `/etat-civil/`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    var data = {
      'social_security': social_security,
      'nb_children': nb_children,
      'is_service_militaire': is_service_militaire,
      'is_activity_abroad': is_activity_abroad,
      'is_demande_rachat_trimestres': is_demande_rachat_trimestres,
    }
    if (beginning_service_militaire) {
      data['beginning_service_militaire'] = beginning_service_militaire
    }
    if (ending_service_militaire) {
      data['ending_service_militaire'] = ending_service_militaire
    }
    if (information_activity_abroad) {
      data['information_activity_abroad'] = information_activity_abroad
    }
    if (rachat_trimestre_information) {
      data['rachat_trimestre_information'] = rachat_trimestre_information
    }

    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  askSalarieValidation (token) {
    const url = apiBaseUrl + `/ask-salarie-validation/`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    var data = {}

    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }


  // -------------------------- DOCUMENT -------------------------------
  getDocument (token, type) {
    // TYPE is a string : ID
    const url = apiBaseUrl + `/get-document/` + type
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  uploadDocument (token, document_pk, file) {
    const url = apiBaseUrl + `/modify-document/` + document_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'multipart/form-data'
    }
    var formData = new FormData()
    formData.append('file', file);
    return axios_instance.put(
      url, formData,
      {
        'headers': headers,
      }
    )
  }

  getOwnProtectedDocument (token, document_pk) {
    //https://thewebtier.com/snippets/download-files-with-axios/
    const url = apiBaseUrl + '/document/protected/file/' + document_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(
      url,
      {
        'headers': headers,
        'responseType': 'blob', // important
      },
    )
  }

  // -------------- BRI ----------
  getBRI (token) {
    // TYPE is a string : ID
    const url = apiBaseUrl + `/get-bri/`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getOwnProtectedBRI (token, bri_pk) {
    //https://thewebtier.com/snippets/download-files-with-axios/
    const url = apiBaseUrl + '/bri/protected/file/' + bri_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(
      url,
      {
        'headers': headers,
        'responseType': 'blob', // important
      },
    )
  }


  // ---------------- APPOINTMENT ----------------

  getForbiddenDates(token) {
    const url = apiBaseUrl + `/forbidden-dates`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getAppointment(token) {
    const url = apiBaseUrl + `/appointment-details/`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getAvailableSchedules(token, day) {
    const url = apiBaseUrl + `/get-schedules/` + day
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  createAppointment (token, day, start, end) {
    const url = apiBaseUrl + `/create-appointment/`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    var data = {
      'day': day,
      'start': start,
      'end': end,
    }

    return axios_instance.post(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  modifyAppointment (token, day, start, end) {
    const url = apiBaseUrl + `/appointment-details/`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    var data = {
      'day': day,
      'start': start,
      'end': end,
    }

    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  deleteAppointment (token) {
    const url = apiBaseUrl + `/appointment-details/`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }

    return axios_instance.delete(
      url,
      {
        'headers': headers,
      }
    )
  }


}
