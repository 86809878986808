export const file_size_validation = (value) =>  {
  if (!value) {
    return true
  }
  if (value == null) {
    return true
  }
  if (value == '') {
    return true
  }
  var file = value
  return (file.size < 5* 1024 * 1024)
}

export const file_extension_validation = (value) =>  {
  if (!value) {
    return true
  }
  if (value == null) {
    return true
  }
  if (value == '') {
    return true
  }
  var file = value

  const accepted_extensions = ['pdf', 'jpg', 'png', 'jpeg']
  var re = /(?:\.([^.]+))?$/
  var ext = re.exec(file.name)[1]

  if (ext) {
    return (accepted_extensions.includes(ext.toLowerCase()))
  }
  else {
    return false
  }
}


export const password_validation = (value) => {
  if (value.length < 8) {
    return false
  }
  var format_special_caracters = /[!@#$%^&*()_+\-=[\]{};':"|,.<>/?]/;
  var format_number = /\d/;
  var format_uppercase = /[A-Z]/;

  var contains_special_caracters = format_special_caracters.test(value)
  var contains_number = format_number.test(value);
  var contains_uppercase = format_uppercase.test(value);

  if (contains_special_caracters && contains_number && contains_uppercase) {
    return true
  }
  return false
}

export const isDate = (value) => {
  if (value == null) return true;
  var regEx = /^\d{4}-\d{2}-\d{2}$/;
  if(!value.match(regEx)) return false;  // Invalid format
  var d = new Date(value);
  var dNum = d.getTime();
  if(!dNum && dNum !== 0) return false; // NaN value, Invalid date
  return d.toISOString().slice(0,10) === value;
}

export const isMuna = (value) => {
  if (value == '') return true;
  if (!value) return true;
  var regEx = /^\d{8}\.\d{2}$/;
  if (!value.match(regEx)) {
    return false;  // Invalid format
  }
  return true
}
