<template>
  <div class="contactComponent">
    <v-icon name="envelope" scale="2"/>
    <span class="ml-2"> Vous pouvez nous contacter pour toute question</span><br>
    <b-button
      class="mt-2"
      variant="outline-secondary"
      size="sm"
      @click="goToContact">
      Accéder au formulaire de contact
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'contactComponent',
  methods: {
    goToContact () {
      this.$router.push('/contact')
    }
  }
}
</script>
