<template>
  <div class="booleanComponent">
    <span v-if="value"> Oui </span>
    <span v-else> Non </span>
  </div>
</template>

<script>
export default {
  name: 'booleanComponent',
  props: {
    value: {
      type: Boolean,
    }
  }
}
</script>
