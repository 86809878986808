<template>
  <div class="formTitleComponent">
    <div class="plateforme-retraite-background form_div_pre_title"
      :style="{ backgroundImage: 'url(' + require('@/static/images/banniere_audiens_pre_title.jpg') + ')' }"> </div>
  </div>
</template>

<script>
export default {
  name: 'formTitleComponent',
}
</script>
<style>
.plateforme-retraite-background {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.form_div_pre_title {
  height: 120px;
}
</style>
